.maincolor {
  background-color: #ffffff;
  text-align: center;
  height: auto;
  vertical-align: middle;
}

.whiteFont{
  color: #ffffff;
}

.myBody{
  background-color: #dfebf5;
  height: 100%;
  color: black;
  margin-top: 130px;
}

.secTop{
  background-color:#0057ac;
  height: 30px;
  margin-top: 90px;
}
.btnWidth{
  width: 70px;
}
.unAuthorised{
  color: red;
  text-align: center;
  height: 10vh;
}
.centerText{
  text-align: center;
}
.Departments{
  width: 200px;
  margin: 5px;
  text-align: center;
}
.descriptionLable{
  width: auto;
  margin: 2px;
  text-align: center;
}
.compliments{
  margin: 5px;
  text-align: center;
}
.isAbout{
  margin: 5px;
  text-align: center;
}
.backBtnColor{
  color: #3468d8;
  font-size: smaller;
  cursor: pointer;
}
.specialBtnColor{
  background-color: #f5a430;
  border-color: #c59b08;
}
.btnColor{
  background-color: #65D834;
}
.btnColor2{
  background-color: #6056f8;
  margin-right: 20px;
}
.withParagraphs{
  white-space: pre-line;
  text-align: justify;
}



.rate {
  text-align: center;
  float:left;
  height: 46px;
}
.rate:not(:checked) > input {
  position:absolute;
  top:-9999px;
}
.rate:not(:checked) > label {
  float:right;
  width:1em;
  overflow:hidden;
  white-space:nowrap;
  cursor:pointer;
  font-size:30px;
  color:#ccc;
}
.rate:not(:checked) > label:before {
  content: '★ ';
}
.rate > input:checked ~ label {
  color: #ffc700;
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
  color: #deb217;
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
  color: #c59b08;
}
.textArea{
  width:100%;
  height:100%;
}

.textAreaDiv{
  width:60%;
  height:80%;
}
.rangeLable{
  font-size: 18px;
  font-weight: normal;
}
.leaveSpace{
  margin-top: 25px;
}
.servicesTable{
  margin-left: auto;
  margin-right: auto;
}
.TableCenter{
  margin-left: auto;
  margin-right: auto;
}